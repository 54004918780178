import { ReactNode } from 'react';

type ChildrenProps = {
  children?: ReactNode;
};

const withStringOrComponent = (
  value: ReactNode,
  WrappedComponent: React.ComponentType<ChildrenProps>,
) => {
  return typeof value === 'string' ? (
    <WrappedComponent>{value}</WrappedComponent>
  ) : (
    value
  );
};

export default withStringOrComponent;
