export function disableScroll() {
  if (!isScrollDisabled()) {
    document.documentElement.style.overflow = 'hidden';
  }
}

export function enableScroll() {
  if (isScrollDisabled()) {
    document.documentElement.style.overflow = 'unset';
  }
}

export function isScrollDisabled() {
  return document.documentElement.style.overflow === 'hidden';
}

export const scrollToTop = () => {
  window && window.scroll({ top: 0 });
};

export const restoreScrollPosition = (url: string) => {
  sessionStorage.setItem(`scrollPos:${url}`, JSON.stringify({ x: 0, y: 0 }));
};
