import { useState, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { Tooltip } from 'components/Toolkit/Tooltip/Tooltip';
import { useVerificationContext } from 'contexts/VerificationContext';

const DEFAULT_TEXT =
  "You'll need to verify your email or phone number before you can place an ad.";

type HorizontalPosProps = [
  'left' | 'center' | 'right',
  'left' | 'center' | 'right',
  'left' | 'center' | 'right',
];

interface VerifyTooltipProps {
  text?: string;
  tooltipPosition?: 'above' | 'below';
  arrowPosition?: HorizontalPosProps;
  children?: ReactNode;
}

const Wrapper = styled.div`
  width: 100%;
`;

function VerifyTooltip({
  tooltipPosition,
  arrowPosition,
  text = DEFAULT_TEXT,
  children,
}: VerifyTooltipProps) {
  const [childWidth, setChildWidth] = useState<number | undefined>(0);
  const ref = useRef<HTMLDivElement>(null);
  const { setIsImproveProfileModalOpen } = useVerificationContext();

  useEffect(() => {
    setChildWidth(ref.current?.getBoundingClientRect().width);
  }, []);

  return (
    <Tooltip
      title="Verify your details"
      subtitle={text}
      hasCloseOption={true}
      hasButton={true}
      buttonType="BUTTON"
      buttonText="Verify Now"
      onHandleTooltipCallback={() => setIsImproveProfileModalOpen(true)}
      arrowPosition={arrowPosition}
      tooltipPosition={tooltipPosition}
      childWidth={childWidth}
    >
      <Wrapper ref={ref}>{children}</Wrapper>
    </Tooltip>
  );
}

export { VerifyTooltip };
