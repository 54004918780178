import { useState } from 'react';
import type { useAccordionProps } from 'components/ToolkitV2/Accordion/Accordion.typed';

/**
 *
 * @param isAlwaysOpen if true will allow multiple accordions to be open at once.
 * @param initialOpenIds the initial open accordion ids.
 */

const useAccordion = (props: useAccordionProps) => {
  const { isAlwaysOpen = true, initialOpenIds = [] } = props;
  const [openIds, setOpenIds] = useState<string[]>(initialOpenIds);

  const onChange = (id: string) => {
    if (checkIsOpen(id)) {
      if (isAlwaysOpen) {
        setOpenIds(openIds.filter((openId) => openId !== id));
      } else {
        setOpenIds([]);
      }
    } else {
      if (isAlwaysOpen) {
        setOpenIds([...openIds, id]);
      } else {
        setOpenIds([id]);
      }
    }
  };

  const checkIsOpen = (id: string) => {
    return openIds.includes(id);
  };

  return {
    onChange,
    checkIsOpen,
    setOpenIds,
  };
};

export { useAccordion };
