import styled from 'styled-components';

import { forwardRef } from 'react';

import { ButtonCSS } from 'components/Toolkit/Button/SharedButton';

import type { ButtonProps } from 'components/Toolkit/Button/SharedButton';

export const SButton = styled.button<ButtonProps>`
  ${ButtonCSS}
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    type = 'button',
    size = 'SMALL',
    ofType = 'PRIMARY',
    isFakeButton,
    inline = false,
    icon,
    children,
    ...rest
  } = props;

  return (
    <SButton
      inline={inline}
      size={size}
      as={isFakeButton ? 'p' : 'button'}
      ofType={ofType}
      type={isFakeButton ? undefined : type}
      {...rest}
      hasIconAndText={Boolean(icon !== undefined && children !== undefined)}
      ref={ref}
    >
      {icon}
      {children}
    </SButton>
  );
});

Button.displayName = 'Button';

export { Button };
