import styled from 'styled-components';
import type { AccordionProps } from 'components/ToolkitV2/Accordion/Accordion.typed';

export const Accordion = styled.div<{ showDivider?: boolean }>`
  border-bottom: ${({ showDivider, theme }) =>
    showDivider ? `1px solid ${theme.colors.GREY_LIGHT}` : 'none'};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-top: ${({ theme }) => theme.spacing.M16};
`;

export const Body = styled.div<Pick<AccordionProps, 'isOpen'>>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const ChevronIconWrapper = styled.div<{ isOpen: boolean }>`
  svg {
    transform: scaleY(${({ isOpen }) => (isOpen ? '-1' : '1')});
    transform-origin: center;
    transition: transform 0.25s ease-in-out; 
`;
