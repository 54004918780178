import { createContext, useContext, useState, ReactNode } from 'react';

interface VerificationProviderProps {
  children: ReactNode;
}

interface VerificationContextI {
  isImproveProfileModalOpen: boolean;
  setIsImproveProfileModalOpen: (t: boolean) => void;
  isPhoneVerificationModalOpen: boolean;
  setIsPhoneVerificationModalOpen: (t: boolean) => void;
  isSendPhoneNumberDisabled: boolean;
  initialPhoneNumber?: string;
  setInitialPhoneNumber: (t: string) => void;
  setIsSendPhoneNumberDisabled: (t: boolean) => void;
  isVerifyAuthCodeDisabled: boolean;
  setIsVerifyAuthCodeDisabled: (t: boolean) => void;
  phoneInputErrorMessage?: string;
  setPhoneInputErrorMessage: (t: string) => void;
  phoneInputButtonText: string;
  setPhoneInputButtonText: (t: string) => void;
  phoneInputDisabled: boolean;
  setPhoneInputDisabled: (t: boolean) => void;
  isEmailVerificationModalOpen: boolean;
  setIsEmailVerificationModalOpen: (t: boolean) => void;
  triggerAfterVerify: boolean;
  setTriggerAfterVerify: (t: boolean) => void;
  showIllDoItLater: boolean;
  setShowIllDoItLater: (t: boolean) => void;
}

const VerificationContext = createContext<VerificationContextI>({
  isImproveProfileModalOpen: false,
  setIsImproveProfileModalOpen: () => null,
  isPhoneVerificationModalOpen: false,
  setIsPhoneVerificationModalOpen: () => null,
  initialPhoneNumber: '',
  setInitialPhoneNumber: () => null,
  isSendPhoneNumberDisabled: false,
  setIsSendPhoneNumberDisabled: () => null,
  isVerifyAuthCodeDisabled: true,
  setIsVerifyAuthCodeDisabled: () => null,
  phoneInputErrorMessage: '',
  setPhoneInputErrorMessage: () => null,
  phoneInputButtonText: '',
  setPhoneInputButtonText: () => null,
  phoneInputDisabled: false,
  setPhoneInputDisabled: () => null,
  isEmailVerificationModalOpen: false,
  setIsEmailVerificationModalOpen: () => null,
  triggerAfterVerify: false,
  setTriggerAfterVerify: () => null,
  showIllDoItLater: true,
  setShowIllDoItLater: () => null,
});

function VerificationProvider({ children }: VerificationProviderProps) {
  const [isImproveProfileModalOpen, setIsImproveProfileModalOpen] =
    useState<boolean>(false);
  const [isPhoneVerificationModalOpen, setIsPhoneVerificationModalOpen] =
    useState<boolean>(false);
  const [initialPhoneNumber, setInitialPhoneNumber] = useState<string>();
  const [isSendPhoneNumberDisabled, setIsSendPhoneNumberDisabled] =
    useState<boolean>(false);
  const [isVerifyAuthCodeDisabled, setIsVerifyAuthCodeDisabled] =
    useState<boolean>(true);
  const [phoneInputErrorMessage, setPhoneInputErrorMessage] = useState<
    string | undefined
  >();
  const [phoneInputButtonText, setPhoneInputButtonText] =
    useState<string>('Send');
  const [phoneInputDisabled, setPhoneInputDisabled] = useState<boolean>(false);
  const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] =
    useState<boolean>(false);
  const [triggerAfterVerify, setTriggerAfterVerify] = useState<boolean>(false);
  const [showIllDoItLater, setShowIllDoItLater] = useState<boolean>(true);

  return (
    <VerificationContext.Provider
      value={{
        isImproveProfileModalOpen,
        setIsImproveProfileModalOpen,
        isPhoneVerificationModalOpen,
        setIsPhoneVerificationModalOpen,
        initialPhoneNumber,
        setInitialPhoneNumber,
        isSendPhoneNumberDisabled,
        setIsSendPhoneNumberDisabled,
        isVerifyAuthCodeDisabled,
        setIsVerifyAuthCodeDisabled,
        phoneInputErrorMessage,
        setPhoneInputErrorMessage,
        phoneInputButtonText,
        setPhoneInputButtonText,
        phoneInputDisabled,
        setPhoneInputDisabled,
        isEmailVerificationModalOpen,
        setIsEmailVerificationModalOpen,
        triggerAfterVerify,
        setTriggerAfterVerify,
        showIllDoItLater,
        setShowIllDoItLater,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
}

const useVerificationContext = () => useContext(VerificationContext);

export { VerificationProvider, useVerificationContext };
