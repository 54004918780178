const withDefaultProps = <T,>(
  WrappedComponent: React.ComponentType<T>,
  defaultProps: Partial<T>,
) => {
  const WithProps = (props: T) => {
    return <WrappedComponent {...defaultProps} {...props} />;
  };

  return WithProps;
};

export default withDefaultProps;
